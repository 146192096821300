<template>
  <div class="page">
    <van-form @submit="onSubmit">
      <van-field readonly clickable @click="showCabinet = true" name="deviceId" v-model="cabinet.name" label="设备" :rules="[{required: true, message: '请填写'}]"/>
      <van-popup v-model="showCabinet" position="bottom">
        <van-picker
            show-toolbar
            :columns="cabinetList.map(it => it.name)"
            @confirm="onConfirmCabinet"
            @cancel="showCabinet = false"
        />
      </van-popup>
      <van-field readonly clickable @click="showRider = true" name="riderName" v-model="rider" label="骑手" :rules="[{required: true, message: '请填写'}]" />
      <van-popup v-model="showRider" position="bottom">
        <van-picker
            show-toolbar
            :columns="riderList.map(it => it.username)"
            @confirm="onConfirmRider"
            @cancel="showRider = false"
        />
      </van-popup>
      <van-field name="amount" v-model="orderCount" label="订单数量" :rules="[{required: true, message: '请填写'}]"/>
      <div style="display: flex;justify-content: center;align-items: center">
        <van-button native-type="submit" round type="info" style="width: 200px;margin: 20px 0">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "request",
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      cabinetList: [],
      cabinet: {deviceId: '', name: ''},
      rider: '',
      riderList: [],
      showAdd: false,
      orderCount: undefined,
      showCabinet: false,
      showRider: false
    }
  },
  created() {
    this.$http.get(this.api + '/cabinet').then(res => {
      this.cabinetList = res.data
    })
    this.$http.get(this.api + '/riderInfo').then(res => {
      this.riderList = res.data
    })
  },
  methods: {
    onConfirmCabinet(value) {
      this.showCabinet = false
      for (let cabinet of this.cabinetList) {
        if (cabinet.name === value) {
          this.cabinet = cabinet
          break
        }
      }
    },
    onConfirmRider(value) {
      this.showRider = false
      this.rider = value
    },

    onSubmit(values) {
      console.log(values)
      this.$toast.loading()
      values.deviceId = this.cabinet.deviceId
      this.$http.post(this.api + '/mockKeep', values).then(res => {
        this.$toast.clear(true)
        this.showAdd = false
        if (res.data === 'good') {
          this.$toast.success('提交成功')
          this.onLoad()
        }
      })
    },
    onRefresh() {
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      this.$http.get(this.api + '/cabinet').then(res => {
        this.loading = false
        this.list = res.data
        this.finished = true
        this.refreshing = false
        console.log(res)
      })
    },
    onDelete(item) {
      this.$dialog({title:'确定删除设备 ' + item.name, showCancelButton: true}).then(() => {
        this.$toast.loading({mask: true})
        this.$http.delete(this.api + '/cabinet/' + item.deviceId).then(res => {
          this.$toast.clear(true)
          if (res.data === 'good') {
            this.onLoad()
          }
        })
      }).catch(() => {
        console.log('cancel')
      })
    }
  }
}
</script>

<style scoped>
.del {
  background-color: red;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.all-room {
  height: calc(100% - 50px);
}
</style>
